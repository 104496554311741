import React from "react";

import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const Marquee = () => {
  
  let imgArray = [
                  {image: "brandsLogos/popchips.png"},
                  {image: "brandsLogos/LemonPerfect.png"},
                  {image: "brandsLogos/MagicSpoon.png"},
                  {image: "brandsLogos/KettleFire.png"},
                  {image: "brandsLogos/Country Archer.png"},
                  {image: "brandsLogos/Belgian Boys.png"}
                ]
  

  return <div className="relative   z-20">
    {/* <div className="flex lg:hidden mx-auto items-center dark-green">

      <h1 className=" ml-0 text-center py-6 font-bold w-full text-xl ">Who We've Helped Grow</h1>
    </div> */}

  <div className="bg-orange-500 inline-block relative w-full overflow-none">
      <div className="marquee relative flex flex-row flex-no-wrap ">
        <div className="marqueeLine w-full">
          {imgArray.map((logo) => {
          return <img className="w-20 marqueeItem object-contain" src={`${logo.image}`} />
          })}
        </div>
        <div className="marqueeLine">
          {imgArray.map((logo) => {
          return <img className="w-20 marqueeItem object-contain" src={`${logo.image}`} />
          })}
        </div>
        <div className="marqueeLine">
          {imgArray.map((logo) => {
          return <img className="w-20 marqueeItem object-contain" src={`${logo.image}`} />
          })}
        </div>
        <div className="marqueeLine">
          {imgArray.map((logo) => {
          return <img className="w-20 marqueeItem object-contain" src={`${logo.image}`} />
          })}
        </div>
      </div>
  </div>
    </div>
};

export default Marquee;
